<template>
  <div class="search-result" data-t="search-result">
    <StCollapse
      v-for="[key, sport] in eventsBySport"
      :id="key"
      :key="key"
      :name="getSportNameById(key)"
      class="sport-group"
      :icon="getSportIcon({ sportId: key }) as IconName"
    >
      <BookmakerSearchItem
        v-for="event in sport"
        :key="event.id"
        :event="event"
      />
    </StCollapse>
  </div>
</template>

<script setup lang="ts">
import { getSportIcon } from 'markets-store/helpers/getSportIcon'
import type { TSportEvent } from 'markets-store'
import { sortBy, groupBy } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import BookmakerSearchItem from './BookmakerSearchItem.vue'

const props = withDefaults(
  defineProps<{
    items: TSportEvent[]
  }>(),
  {
    items: () => [],
  },
)

const customMenu = useCustomMenu()
function getSportNameById(id: number) {
  return (
    customMenu.value.menu.find((item) => item.id === Number(id))?.name ?? ''
  )
}
const eventsBySport = computed(() =>
  groupBy(
    sortBy(props.items, (item) => [item.scheduled]),
    (event) => event.sportId,
  ),
)
</script>

<style scoped>
.search-result {
  background-color: var(--background-base);
  border-radius: var(--border-radius-150);
}

.sport-group {
  &:deep(.content) {
    padding: var(--spacing-050);
  }
}
</style>
