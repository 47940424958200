<template>
  <div class="event-item-compact" data-t="event-item">
    <NuxtI18nLink :to="event.url ?? '/'" class="link">
      <div class="top">
        <div class="competitor-names" data-t="competitor-names">
          <span data-t="competitor-home" class="competitor-name">
            {{ event.home.name }}
          </span>
          <span> – </span>
          <span data-t="competitor-away" class="competitor-name">
            {{ event.away.name }}
          </span>
        </div>
      </div>
      <div class="bottom">
        <EventStatusBar :event="event" />
        <EventMarketsCount
          :count="event.activeMarketsCount"
          :link="event.url"
          is-visible-separator
        />
      </div>
    </NuxtI18nLink>
  </div>
</template>

<script setup lang="ts">
import type { TSportEvent } from 'markets-store'
import EventStatusBar from '../EventStatusBar/EventStatusBar.vue'

const props = defineProps<{
  event: TSportEvent
}>()

const { event } = toRefs(props)
</script>

<style scoped>
.link {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);

  padding: var(--spacing-125) var(--spacing-100);

  color: inherit;
  text-decoration: none;
}

.event-item-compact {
  position: relative;
  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  &:not(:first-child)::after {
    content: '';

    position: absolute;
    top: 0;
    right: var(--spacing-100);
    left: var(--spacing-100);

    height: 1px;

    background-color: rgb(255 255 255 / 8%);
  }

  &:hover {
    background-color: var(--background-secondary);

    &::after {
      opacity: 0;
    }

    + .event-item-compact {
      &::after {
        opacity: 0;
      }
    }
  }
}

.competitor-names {
  display: inline-flex;
  gap: var(--spacing-050);
  align-items: center;

  color: var(--text-primary);
  text-decoration: none;

  &:hover {
    color: var(--text-link);
  }
}

.competitor-name {
  overflow: hidden;
  font: var(--desktop-text-sm-medium);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bottom {
  display: flex;
  gap: var(--spacing-075);
  align-items: center;
}
</style>
