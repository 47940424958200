<template>
  <section class="search" data-t="search">
    <h2 class="title">{{ t('search.title') }}</h2>
    <form class="search-form">
      <StInput
        v-model="searchValue"
        :placeholder="t('search.buttonPlacholder')"
        autofocus
        data-t="search-input"
        icon-prefix="search"
      >
        <template #icon-posfix>
          <StIcon
            v-if="searchValue"
            :size="20"
            class="reset-icon"
            name="cross-large"
            @click="resetSearch"
          />
        </template>
      </StInput>
    </form>
    <div class="search-wrapper">
      <div class="result">
        <StTabs
          v-if="searchValue.length >= 3"
          v-model="selectedTab"
          :tabs="tabs"
          type="separate"
        />
        <Transition>
          <div v-if="searchValue.length >= 3" class="result-list">
            <CasinoSearchResult
              v-if="selectedTab === 'casino' && casinoGames.length"
              :items="casinoGames"
            />
            <BookmakerSearchResult
              v-else-if="selectedTab === 'sport' && sportEvents.length"
              :items="sportEvents"
            />
            <BookmakerSearchResult
              v-else-if="
                selectedTab === 'cybersport' && cybersportEvents.length
              "
              :items="cybersportEvents"
            />
            <div v-else class="nothing-found">
              <StIcon class="nothing-icon" name="search-none" size="64" />
              <h2 class="nothing-header">{{ t('search.nothing') }}</h2>
              <p class="nothing-info">{{ t('search.changeRequest') }}</p>
            </div>
          </div>
        </Transition>
      </div>
      <Transition>
        <div
          v-if="searchValue.length < 3"
          class="empty-search"
          data-t="empty-search"
        >
          <img height="140" src="./empty.png" width="248" />
          <p>{{ t('search.empty') }}</p>
        </div>
      </Transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import BookmakerSearchResult from '@st/bookmaker/components/BookmakerSearchResult/BookmakerSearchResult.vue'
import CasinoSearchResult from '@st/casino/components/CasinoSearchResult/CasinoSearchResult.vue'
import { useSearch } from './useSearch'

const { t } = useI18n()
const {
  resetSearch,
  searchValue,
  tabs,
  selectedTab,
  sportEvents,
  cybersportEvents,
  casinoGames,
} = useSearch()
</script>

<style scoped>
.search {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  width: 596px;
  min-height: min(85vh, 760px);
  max-height: min(85vh, 760px);
  padding: var(--spacing-300);

  background: var(--background-base);
  border-radius: var(--border-radius-150, 12px);
  box-shadow: 0 0 80px -8px #1d1d2a;
}

.search-form {
  margin-top: var(--spacing-200);
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
  color: var(--text-primary);
}

.reset-icon {
  cursor: pointer;
  color: var(--text-tertiary);

  &:hover {
    color: var(--text-primary);
  }
}

.result {
  display: flex;
  flex-direction: column;
  min-height: 0;
  margin-top: var(--spacing-300);
}

.result-list {
  scrollbar-color: var(--background-tertiary);
  scrollbar-width: thin;

  overflow: hidden;
  overflow-y: auto;

  margin-top: var(--spacing-300);
  margin-right: calc(var(--spacing-200) * -1);
  padding-right: var(--spacing-200);
}

.search-wrapper {
  position: relative;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;

  min-height: 0;
}

.empty-search {
  position: absolute;
  top: 50%;
  right: var(--spacing-200);
  left: var(--spacing-200);
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-600) 0;

  p {
    margin: 0;
    margin-top: var(--spacing-300);
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.25s ease-in-out,
    transform 0.35s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.nothing-found {
  position: absolute;
  top: 50%;
  right: var(--spacing-200);
  left: var(--spacing-200);
  transform: translateY(-50%);

  text-align: center;

  * {
    margin: 0;
  }
}

.nothing-icon {
  margin-right: var(--spacing-050);
  margin-bottom: var(--spacing-300);
  opacity: 0.24;
}

.nothing-header {
  margin-bottom: var(--spacing-025);
  font: var(--desktop-text-lg-medium);
}

.nothing-info {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}
</style>
